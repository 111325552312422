import React, {
    useReducer, useEffect, useContext,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar,
    IconButton, Typography, Slide, Chip, ButtonGroup,
    CircularProgress,
    Switch,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import {
    SaveIcon, CloseIcon, SendIcon, AddIcon, CachedIcon,
} from 'components/icons';
import {
    Row, Form, Col, InputGroup,
} from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import ElectronicPaymentSchema from 'utils/schema/accounting/accountReceivable/ElectronicPaymentSchema';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import DialogActions from '@material-ui/core/DialogActions';
import CatalogSelect from 'components/widgets/catalogs/CatalogSelect';
import UserContext from 'components/context/UserContext';
import { useHistory, useParams } from 'react-router';
import { modules } from 'utils/enum/modules';
import { FetchPolicy } from 'utils/enum/Core';
import AccountingUtils from 'utils/AccountingUtils';
import {
    AccountingSubModules, AccountsReceivableEntities,
    CashierTakePaymentStatus,
    ElectronicPaymentOption,
} from 'utils/enum/AccountingEnum';
import AccountReceivableMutation from 'services/graphQL/mutate/accounting/AccountReceivableMutation';
import { isEmpty } from 'lodash';
import AccountReceivablesMapper from 'services/mapData/AccountReceivablesMapper';
// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import DropdownQuery from 'components/widgets/DropdownQuery';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import clsx from 'clsx';
import { v1 as uuid } from 'uuid';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import CompanyQuery from 'services/graphQL/query/setting/CompanyQuery';

const keyStore = new KeyStore();

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));
const ownStyle = makeStyles(() => ({
    flexauto: {
        flex: 'auto',
    },
    hideSection: {
        visibility: 'hidden',
        height: '10px',
        overflow: 'auto',
    },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValues',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_INFO_WHEN_NOT_ALLOWED_REASON: 'setInfoWhenNotAllowedReason',
};

const getFeeTypeByPaymentMethod = (accountType = '') => {
    switch (accountType.toUpperCase()) {
    case 'BUSINESS SAVINGS':
    case 'BUSINESS CHECKING':
    case 'PERSONAL CHECKING':
    case 'PERSONAL SAVINGS':
        return 'ach';
    case 'DEBIT':
    case 'PREPAID':
        return 'debit';
    case 'CREDIT':
        return 'credit';
    default:
        return '';
    }
};

const ElectronicPaymentReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
            isNew: action.value.cashierElectronicPaymentId === '',
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value, isDirty } = action;
        return { ...state, record: value, isDirty };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction && action.fireActionParam) action.fireAction(action.fireActionParam);
        if (action.fireAction && !action.fireActionParam) action.fireAction();

        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_INFO_WHEN_NOT_ALLOWED_REASON: {
        if (action.fireAction) action.fireAction();

        return {
            ...state,
            record: {
                ...state.record,
                orderIdentifier: '',
                amount: 0,
                orderAmount: 0,
            },
            embeddedJSUrl: '',
        };
    }
    default:
        return state;
    }
};

const paymentOptionData = [
    { id: ElectronicPaymentOption.ELECTRONIC_PAYMENT, description: ElectronicPaymentOption.ELECTRONIC_PAYMENT },
    { id: ElectronicPaymentOption.EMAIL_SMART_LINK, description: ElectronicPaymentOption.EMAIL_SMART_LINK },
    { id: ElectronicPaymentOption.SMS_LINK, description: ElectronicPaymentOption.SMS_LINK },
    { id: ElectronicPaymentOption.KIOSK_OR_PORTAL, description: ElectronicPaymentOption.KIOSK_OR_PORTAL },
];

const ElectronicPaymentCreate = () => {
    const ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS);

    const { availableLots, defaultLot, defaultLotId } = useContext(UserContext);
    const overrideAvailableLots = (availableLots || []).map((item) => ({ lotId: item.id, lotName: item.label }));

    const { entity, accountId, id } = useParams();
    const history = useHistory();

    const comeFromOtherCollection = entity === AccountsReceivableEntities.OTHER_COLLECTIONS;
    const comeFromPettyCash = entity === AccountsReceivableEntities.PETTY_CASH;
    const inViewMode = Number(id ?? 0) !== 0;

    const initialState = {
        record: {
            cashierElectronicPaymentId: String(id ?? ''),
            accountNumber: Number(accountId ?? 0),
            transactionType: AccountingUtils.getTransactionType(entity),
            lotName: defaultLot === 'null' || defaultLot.length === 0 ? null : defaultLot,
            lotId: defaultLotId,
            reason: '',
            memo: '',
            amount: 0,
            paymentMethodId: '',
            orderIdentifier: '',
            status: CashierTakePaymentStatus.NEW,
            paymentOptionId: '',
            orderAmount: 0,
            waiveConvenienceFee: false,
            convenienceFee: 0,
        },
        isDirty: false,
        isNew: true,
        couldLostData: false,
        openTakePayment: false,
        depositedValues: [],
        disableAddPaymentMethod: true,
        embeddedJSUrl: '',
    };

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const [state, dispatch] = useReducer(ElectronicPaymentReducer, initialState);
    const {
        record, isDirty, couldLostData, disableAddPaymentMethod,
    } = state;

    const {
        reason, paymentOptionId, waiveConvenienceFee,
    } = record;

    const isValidData = isValidSchema(ElectronicPaymentSchema, record);
    const { isValid, errors } = isValidData;

    const {
        data: providerData, loading: providerLoading, error: providerError,
    } = useQuery(CompanyQuery.GET_PAYMENT_PROVIDER, {
        variables: {},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (providerError) {
            ModalUtils.errorMessage(providerError?.graphQLErrors);
        }
    }, [providerLoading, providerError, providerData]);

    const [getElectronicPayment, { loading }] = useLazyQuery(AccountReceivableQuery.GET_ELECTRONIC_PAYMENT, {
        onCompleted: (res) => {
            if (res.getElectronicPayment) {
                const result = { ...res.getElectronicPayment };

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...result,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const createIFrameForPayment = (scriptUrl) => {
        if (!isEmpty(scriptUrl)) {
            const script = document.createElement(
                'script',
            );
            script.src = `${scriptUrl}?v=${uuid()}&only=debit,ach,credit`;
            script.type = 'text/javascript';
            script.defer = false;
            script.setAttribute(
                'data-button',
                'createPaymentMethodId',
            );
            script.setAttribute(
                'data-callback',
                'callback',
            ); // This callback is assigned to a non-existing function to avoid the page to be reloaded when closing the popup
            document.body.appendChild(script);
            script.onload = () => {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        disableAddPaymentMethod: false,
                    },
                });
                window.pnm__build();
            };
        }
    };

    const removeIframePayment = () => {
        const script = document.querySelector('script[data-button="createPaymentMethodId"]');
        if (script) { script.remove(); }

        // Remove the div containing the iframe with the class "pnm_if"
        const iframe = document.querySelector('iframe.pnm_if');
        if (iframe) {
            const iframeContainer = iframe;
            if (iframeContainer) { iframeContainer.remove(); }
        }
    };

    const [getPaymentMethods, { loading: loadingPaymentMethods, data: paymentMethodList }] = useLazyQuery(AccountingQuery.GET_ELECTRONIC_PAYMENT_METHODS, {
        variables: {
            accountNumber: Number(accountId),
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getFees, { data: dataFees }] = useLazyQuery(AccountingQuery.GET_FEES, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onGetFees = (orderIdentifier) => {
        getFees({
            variables: {
                orderIdentifier,
            },
        });
    };

    const [getOrder, { loading: orderLoading }] = useLazyQuery(AccountingQuery.FIND_ORDER, {
        onCompleted: (res) => {
            if (res.findOrderAccountReceivables) {
                const result = { ...res.findOrderAccountReceivables };

                if (!isEmpty(result.pnmOrderIdentifier)) {
                    onGetFees(result.pnmOrderIdentifier);
                }
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: {
                            ...record,
                            orderIdentifier: result.pnmOrderIdentifier,
                            amount: Number(result.balanceDue),
                            orderAmount: Number(result.balanceDue),
                        },
                        embeddedJSUrl: result?.electronicPayments?.embeddedJSUrl ?? '',
                    },
                    fireAction: createIFrameForPayment,
                    fireActionParam: result?.electronicPayments?.embeddedJSUrl ?? '',
                });
            }
        },
        onError: (errorMessage) => {
            dispatch({
                type: ACTION_TYPES.SET_INFO_WHEN_NOT_ALLOWED_REASON,
                fireAction: removeIframePayment,
            });
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [createOrder, { loading: isSavingOrder }] = useMutation(AccountReceivableMutation.CREATE_ELECTRONIC_ORDER, {
        onCompleted: (mutationData) => {
            if (mutationData?.createOrder) {
                const result = { ...mutationData.createOrder };

                if (!isEmpty(result.pnmOrderIdentifier)) {
                    onGetFees(result.pnmOrderIdentifier);
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: {
                            ...record,
                            orderIdentifier: result.pnmOrderIdentifier,
                            amount: Number(result.orderAmount),
                            orderAmount: Number(result.orderAmount),
                        },
                        embeddedJSUrl: result.electronicPayments.embeddedJSUrl,
                    },
                    fireAction: createIFrameForPayment,
                    fireActionParam: result.electronicPayments.embeddedJSUrl,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onCreateOrder = async () => {
        await createOrder({
            variables: {
                accountNumber: record.accountNumber,
                processType: record.transactionType,
                reason: record.reason,
                amount: record.amount,
            },
        });
    };

    const onClearForm = () => {
        const currentRecord = { ...record };
        const initialRecord = initialState.record;

        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: {
                ...initialState,
                record: {
                    ...initialRecord,
                    accountNumber: currentRecord.accountNumber,
                    cashierElectronicPaymentId: currentRecord.cashierElectronicPaymentId,
                    customer: ' ',
                },
            },
        });
    };

    const onPopupClose = (event, forceClose = false) => {
        if (isDirty && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        removeIframePayment();
        setTimeout(() => {
            onClearForm();
            history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/${entity}`);
        }, 300);
    };

    const [sendSMSSmartLink, { loading: isSendingSMS }] = useMutation(AccountingMutation.SEND_SMS_SMART_LINK, {
        onCompleted: (mutationData) => {
            if (mutationData?.sendSMSSmartLink) {
                ModalUtils.successMessage(null, 'SMS sent successfully');
                onPopupClose(null, true);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [sendEmailSmartLink, { loading: isSendingEmail }] = useMutation(AccountingMutation.SEND_EMAIL_SMART_LINK, {
        onCompleted: (mutationData) => {
            if (mutationData?.sendEmailSmartLink) {
                ModalUtils.successMessage(null, 'Email sent successfully');
                onPopupClose(null, true);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const mutationQuery = AccountReceivableMutation.CREATE_ELECTRONIC_PAYMENT;
    const [addRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (mutationData) => {
            if (mutationData?.createElectronicPayment) {
                ModalUtils.successMessage(null, 'Successfully saved!');

                onPopupClose(null, true);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        if (paymentOptionId === ElectronicPaymentOption.ELECTRONIC_PAYMENT) {
            const recordToSave = AccountReceivablesMapper.mapTakeElectronicPaymentToSave(record);

            await addRecord({
                variables: {
                    record: recordToSave,
                },
            });
        } else if (paymentOptionId === ElectronicPaymentOption.EMAIL_SMART_LINK) {
            await sendEmailSmartLink({
                variables: {
                    accountNumber: record.accountNumber,
                    processType: record.transactionType,
                    reason: record.reason,
                },
            });
        } else if (paymentOptionId === ElectronicPaymentOption.SMS_LINK) {
            await sendSMSSmartLink({
                variables: {
                    accountNumber: record.accountNumber,
                    processType: record.transactionType,
                    reason: record.reason,
                },
            });
        } else if (paymentOptionId === ElectronicPaymentOption.KIOSK_OR_PORTAL) {
            ModalUtils.successMessage(null, 'You are able to pay in Kiosk or Portal!');

            onPopupClose(null, true);
        }
    };

    const onChangeValue = (field, value) => {
        let checkDirty = true;

        if (comeFromPettyCash
            && !Number.isNaN(value)
            && Math.abs(value) === Math.abs(record[field])) checkDirty = false;

        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        if (field === 'paymentOptionId' && value !== ElectronicPaymentOption.ELECTRONIC_PAYMENT) {
            currentRecord.paymentMethodId = '';
            currentRecord.amount = currentRecord.orderAmount;
        }

        if (field === 'lotId' && defaultLotId === 0) {
            currentRecord.lotName = overrideAvailableLots.find((c) => c.lotId === value)?.lotName;
        }

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                record: currentRecord,
                isDirty: checkDirty,
            },
            fireAction: field === 'paymentOptionId' && value === ElectronicPaymentOption.ELECTRONIC_PAYMENT ? getPaymentMethods : null,
        });
    };

    const handlePaymentMethodChange = (columnId, newValue, _, additionalFieldsReturned) => {
        const currentFeeType = getFeeTypeByPaymentMethod(additionalFieldsReturned.accountType);
        const feeAmount = dataFees?.getFees?.find((c) => c.type.toLowerCase() === currentFeeType.toLowerCase())?.fees[0].consumerFeeAmount ?? 0;

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                record: {
                    ...record,
                    [columnId]: newValue,
                    convenienceFee: feeAmount,
                },
                isDirty: true,
            },
        });
    };

    useEffect(() => {
        if (record.cashierElectronicPaymentId !== '') {
            getElectronicPayment({
                variables: {
                    transactionNumber: record.cashierElectronicPaymentId,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity, record.cashierElectronicPaymentId]);

    useEffect(() => {
        if (accountId > 0 && !isEmpty(reason) && isEmpty(id)) {
            getOrder({
                variables: {
                    accountNumber: Number(accountId ?? 0),
                    processType: AccountingUtils.getTransactionType(entity),
                    reason,
                },
            });
        } else removeIframePayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, reason]);

    const getTitleForm = () => {
        if (inViewMode) {
            return `View Payment ${isEmpty(record.providerTransactionPaymentId)
                ? record.cashierElectronicPaymentId : record.providerTransactionPaymentId}`;
        }
        return `New Payment (${record.accountNumber}) - ${record.orderIdentifier}`;
    };

    const getValidForm = () => {
        if (isValid && record.amount !== 0 && isDirty && !comeFromOtherCollection && !comeFromPettyCash && !isSaving) return true;
        if (isValid && !isEmpty(record.customer) && (comeFromOtherCollection || comeFromPettyCash) && isDirty && !isSaving) return true;
        return false;
    };

    const getStatusChip = () => {
        if (record.status.toLowerCase() === CashierTakePaymentStatus.NEW.toLowerCase()) return null;

        let badgeStatus = classes.primary;
        if (record.status.toLowerCase() === CashierTakePaymentStatus.DEPOSITED.toLowerCase()
        || record.status.toLowerCase() === CashierTakePaymentStatus.PARTIALLY_DEPOSIT.toLowerCase()) badgeStatus = classes.badgeApproved;
        // eslint-disable-next-line max-len
        else if ([CashierTakePaymentStatus.VOIDED.toLowerCase(), CashierTakePaymentStatus.DECLINED.toLowerCase(), CashierTakePaymentStatus.REFUNDED.toLowerCase()].includes(record.status.toLowerCase())) { badgeStatus = classes.badgeVoid; }

        return (
            <>
                <span className={classes.fontSmall}>Status: </span>
                <Chip size="small" label={record.status} className={badgeStatus} />
            </>
        );
    };

    const orderExist = record.orderIdentifier !== '';

    return (
        <Dialog
            open
            onClose={onPopupClose}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
            disableEnforceFocus
            disableRestoreFocus
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        { getTitleForm()}
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.noPadding}>
                { (loading || orderLoading || isSavingOrder) ? <LoadingMask />
                    : (
                        <Container className={classes.containerSplit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={4} className="required">Reason *</Form.Label>
                                        <Col xs={8}>
                                            <CatalogSelect
                                                catalogEnum={AccountingUtils.getCatalogReason(entity)}
                                                catalogTitle="Reason for Electronic Payments"
                                                name="reason"
                                                className={isValidField(errors, 'reason') ? 'invalid-field' : ''}
                                                value={reason}
                                                placeHolder="select the reason"
                                                onChange={(name, newValue) => onChangeValue('reason', newValue)}
                                                disabled={inViewMode}
                                                defaultEmptyLineId=""
                                            />
                                        </Col>
                                    </Form.Group>
                                    {reason !== ''
                                    && (
                                        <>
                                            {orderExist && !inViewMode && (
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" xs={4} className="required">Total Due</Form.Label>
                                                    <Col xs={3}>
                                                        <InputNumber
                                                            size="sm"
                                                            showCurrency
                                                            value={record.orderAmount}
                                                            thousandSeparator
                                                            placeholder="Total Due"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            )}
                                            <Form.Group as={Row}>
                                                <Form.Label column="sm" xs={4} className="required">Amount Received</Form.Label>
                                                <Col xs={3}>
                                                    <InputNumber
                                                        size="sm"
                                                        showCurrency
                                                        value={record.amount}
                                                        className={isValidField(errors, 'amount') ? 'invalid-field' : ''}
                                                        thousandSeparator
                                                        placeholder="Amount"
                                                        onChange={(value) => onChangeValue('amount', value)}
                                                        disabled={inViewMode || !orderExist || paymentOptionId !== ElectronicPaymentOption.ELECTRONIC_PAYMENT}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {inViewMode && (
                                                <>
                                                    <Form.Group as={Row}>
                                                        <Form.Label column="sm" xs={4} className="required">Waive Convenience Fee</Form.Label>
                                                        <Col xs={3}>
                                                            <InputNumber
                                                                size="sm"
                                                                showCurrency
                                                                value={record.convenienceFee}
                                                                thousandSeparator
                                                                placeholder="Convenience"
                                                                disabled
                                                            />
                                                        </Col>
                                                        <Col xs={5}>
                                                            <Switch
                                                                checked={Boolean(waiveConvenienceFee)}
                                                                color={waiveConvenienceFee ? 'primary' : 'secondary'}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row}>
                                                        <Form.Label column="sm" xs={4} className="required">Total Paid</Form.Label>
                                                        <Col xs={3}>
                                                            <InputNumber
                                                                size="sm"
                                                                showCurrency
                                                                value={Number(record.amount) + (waiveConvenienceFee ? 0 : Number(record.convenienceFee))}
                                                                thousandSeparator
                                                                placeholder="Total Due"
                                                                disabled
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </>
                                            )}
                                            {orderExist && !inViewMode && (
                                                <>
                                                    <Form.Group as={Row}>
                                                        <Form.Label column="sm" xs={4} className="required">Payment Options</Form.Label>
                                                        <Col xs={8}>
                                                            <DropdownQuery
                                                                name="paymentOptionId"
                                                                className={isValidField(errors, 'paymentOptionId') ? 'invalid-field' : ''}
                                                                value={paymentOptionId}
                                                                placeHolder="select"
                                                                onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                                dataSource={{
                                                                    localData: paymentOptionData,
                                                                    idField: 'id',
                                                                    descriptionField: 'description',
                                                                }}
                                                                disabled={inViewMode}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    {(defaultLotId === 0) && (
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" xs={4} className="required">Lot</Form.Label>
                                                            <Col xs={8}>
                                                                <DropdownQuery
                                                                    name="lotId"
                                                                    className={isValidField(errors, 'lotName') ? 'invalid-field' : ''}
                                                                    value={record.lotId}
                                                                    placeHolder="select a lot"
                                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                                    dataSource={{
                                                                        localData: overrideAvailableLots,
                                                                        idField: 'lotId',
                                                                        descriptionField: 'lotName',
                                                                    }}
                                                                    disabled={inViewMode}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    )}
                                                    <div className={paymentOptionId === ElectronicPaymentOption.ELECTRONIC_PAYMENT ? '' : classes.hideSection}>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" xs={4} className="required">Payment Method</Form.Label>
                                                            <Col xs={8}>
                                                                <InputGroup size="md">
                                                                    <DropdownQuery
                                                                        name="paymentMethodId"
                                                                        value={record.paymentMethodId}
                                                                        placeholder="Select"
                                                                        className={clsx(classes.flexauto,
                                                                            isValidField(errors, 'paymentMethodId') ? 'invalid-field' : '')}
                                                                        onChange={handlePaymentMethodChange}
                                                                        dataSource={{
                                                                            localData: paymentMethodList?.getPaymentMethods ?? [],
                                                                            idField: 'paymentMethodIdentifier',
                                                                            descriptionField: 'fullDescription',
                                                                            additionalFieldsReturned: ['feeAmount', 'accountType'],
                                                                        }}
                                                                        defaultEmptyLineId=""
                                                                        defaultEmptyLineText="Select"
                                                                        disabled={paymentOptionId !== ElectronicPaymentOption.ELECTRONIC_PAYMENT || inViewMode}
                                                                    />
                                                                    <ButtonGroup color="primary" variant="contained">
                                                                        <Button
                                                                            className={classes.buttonNew}
                                                                            startIcon={<AddIcon />}
                                                                            size="small"
                                                                            disabled={disableAddPaymentMethod
                                                                                || paymentOptionId !== ElectronicPaymentOption.ELECTRONIC_PAYMENT || inViewMode}
                                                                            id="createPaymentMethodId"
                                                                        >
                                                                            Add
                                                                        </Button>
                                                                        <Button
                                                                            className={classes.buttonSave}
                                                                            size="small"
                                                                            onClick={getPaymentMethods}
                                                                            disabled={disableAddPaymentMethod
                                                                                || paymentOptionId !== ElectronicPaymentOption.ELECTRONIC_PAYMENT || inViewMode}
                                                                        >
                                                                            {loadingPaymentMethods ? <CircularProgress size={24} color="primary" />
                                                                                : <CachedIcon />}
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" xs={4} className="required">Waive Convenience Fee</Form.Label>
                                                            <Col xs={3}>
                                                                <InputNumber
                                                                    size="sm"
                                                                    showCurrency
                                                                    value={record.convenienceFee}
                                                                    thousandSeparator
                                                                    placeholder="Convenience"
                                                                    disabled
                                                                />
                                                            </Col>
                                                            <Col xs={5}>
                                                                <Switch
                                                                    checked={Boolean(waiveConvenienceFee)}
                                                                    color={waiveConvenienceFee ? 'primary' : 'secondary'}
                                                                    onChange={(e) => onChangeValue('waiveConvenienceFee', e.target.checked)}
                                                                    disabled={inViewMode}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" xs={4} className="required">Total Paid</Form.Label>
                                                            <Col xs={3}>
                                                                <InputNumber
                                                                    size="sm"
                                                                    showCurrency
                                                                    value={Number(record.amount) + (waiveConvenienceFee ? 0 : Number(record.convenienceFee))}
                                                                    thousandSeparator
                                                                    placeholder="Total Due"
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" xs={4} className="required">Memo</Form.Label>
                                                            <Col xs={8}>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    className={isValidField(errors, 'memo') ? 'invalid-field' : ''}
                                                                    size="sm"
                                                                    rows="3"
                                                                    value={record.memo || ''}
                                                                    placeholder="Memo"
                                                                    onChange={(e) => onChangeValue('memo', e.target.value)}
                                                                    disabled={inViewMode}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <ConfirmDialog
                                title="Attention - You have unsaved changes!"
                                description="Do you want to close without saving?"
                                open={couldLostData}
                                variant="outlined"
                                titlePrimary="Close"
                                titleSecondary="Cancel"
                                onClose={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } })}
                                onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } })}
                                onClickPrimary={(e) => onPopupClose(e, true)}
                            />
                        </Container>
                    )}
            </DialogContent>

            <DialogActions className={classes.borderTop}>
                <div className={classes.fullWidth}>
                    <Toolbar className={classes.centerItems}>
                        <div className={classes.buttonSpacing}>
                            { getStatusChip() }
                        </div>
                        {!inViewMode && ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS
                    && (
                        <div className={classes.buttonSpacing}>
                            {!orderExist
                                && (
                                    <Button
                                        className={classes.buttonSave}
                                        size="small"
                                        disabled={reason === '' || record.amount === 0}
                                        onClick={onCreateOrder}
                                    >
                                        Next
                                    </Button>
                                )}
                            {orderExist && paymentOptionId === ElectronicPaymentOption.ELECTRONIC_PAYMENT && (
                                <Button
                                    className={classes.buttonSave}
                                    startIcon={<SaveIcon />}
                                    size="small"
                                    disabled={!getValidForm()}
                                    onClick={onSave}
                                >
                                    Take Payment
                                </Button>
                            )}
                            {orderExist && [ElectronicPaymentOption.EMAIL_SMART_LINK,
                                ElectronicPaymentOption.SMS_LINK, ElectronicPaymentOption.KIOSK_OR_PORTAL].includes(paymentOptionId) && (
                                <Button
                                    className={classes.buttonSave}
                                    startIcon={paymentOptionId === ElectronicPaymentOption.KIOSK_OR_PORTAL ? <SaveIcon /> : <SendIcon />}
                                    size="small"
                                    disabled={isEmpty(reason) || record.amount === 0}
                                    onClick={onSave}
                                >
                                    {paymentOptionId === ElectronicPaymentOption.KIOSK_OR_PORTAL ? 'Save' : 'Send' }
                                </Button>
                            )}
                        </div>
                    )}
                    </Toolbar>
                </div>
            </DialogActions>
            {isSaving && <DialogActionMessage message="Saving information... " />}
            {(isSendingSMS || isSendingEmail) && <DialogActionMessage message="Sending information... " />}
        </Dialog>
    );
};

export default ElectronicPaymentCreate;
